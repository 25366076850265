.sns_modal {
    top: 0;
    position: fixed;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 10001;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .sns_modal-content {
    width: 80%;
    height: 80%;
    background: white;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    position: relative;
  }
  
  .sns-modal-container {
    display: flex;
    height: 100%;
  }
  
  .post-image-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    

  }
  
  .post-info-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-left: 1px solid #626161;

  }
  
  .post-info {
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  
  .post-author {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .post-content {
    font-size: 14px;
  }
  
  .comments-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    position: relative; 
    padding-bottom: 50px; 
  }
  
  .comments-header {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .comments-list {
    flex: 1;
    overflow-y: auto;
  }
  
  .comment {
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
  }
  
  .comment-author {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 5px;
  }
  
  .comment-content {
    font-size: 14px;
    margin-bottom: 5px;
  }
  
  .comment-date {
    font-size: 12px;
    color: #888;
  }
  
  .reply-button {
    font-size: 12px;
    color: #666;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  .reply-button.active {
    color: #333;
  }
  
  .replies {
    margin-top: 10px;
    margin-left: 20px; 
    padding-left: 10px; 
    border-left: 2px solid #eaeaea; 
  }
  
  .reply {
    padding: 8px 12px; 
    background-color: #fafafa; 
    margin-bottom: 10px;
    font-size: 0.9rem;
    border-radius: 5px; 
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); 
  }
  
  .reply-author {
    font-weight: bold;
    font-size: 13px; 
    margin-bottom: 4px;
  }
  .reply-content {
    font-size: 14px;
    margin-bottom: 5px;
  }
  
  .reply-date {
    font-size: 12px;
    color: #888;
  }
  
  .close-button {
    align-self: flex-end;
    margin: 10px;
    padding: 5px 10px;
    background-color: #c70a0a;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    position: absolute; 
    top: 10px; 
    right: 20px; 
    z-index: 10;
  }
  .sns-comment-input {
    flex: 1;
    /* align-self: flex-end;
    border: none;
    padding: 8px; */
    margin-right: 8px;
  }
  .sns-comments-input-divider {
    height: 1px;
    background-color: #ccc; /* Divider color */
    margin-top: 20px; /* Space above the divider */
  }
  
  .sns-comment-input-container {
    position: absolute; /* Make the input container stick to the bottom */
    bottom: 10px; /* Space from the bottom */
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    padding: 0 20px; /* Padding on the sides */
  }
  .sns-post-comment-button {
    padding: 8px 16px; /* Padding for the button */
    background-color: #007bff; /* Example button color */
    color: white; /* Text color */
    border: none;
    border-radius: 4px; /* Rounded corners for the button */
    cursor: pointer;
  }.comment-body, .reply-body {
    display: flex;
    flex-direction: column;
    margin-left: 10px; /* Space between image and text */
  }
  
  .sns-profile-img {
    width: 20px; 
    height: 20px; 
    display:;
    border-radius: 15px; 
    object-fit: cover;
    margin-right: 0px;
  }
  .menu-modal {
    position: absolute;
    top: 40px; /* Adjust the value to position the modal below your menu button */
    right: 20px;
    width: 200px; /* Set the width of the modal */
    background: #ffffff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    z-index: 10002; /* Ensure it's above other content */
  }
  
  .menu-modal ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  .menu-modal li {
    padding: 10px;
    border-bottom: 1px solid #f0f0f0;
    cursor: pointer;
  }
  
  .menu-modal li:last-child {
    border-bottom: none;
  }
  
  .menu-modal li:hover {
    background-color: #f8f8f8;
  }
  
  .menu-button {
    position: absolute;
    top: 30px; /* Adjust this value as needed */
    right: 20px; 
    background: none;
    border: none;
    font-size: 24px; /* Adjust the size as needed */
    cursor: pointer;
  }
  .confirm-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10002; 
  }
  
  .confirm-modal {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .confirm-modal-message {
    margin-bottom: 20px;
  }
  
  .confirm-modal-actions {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
  
  .confirm-modal-button {
    padding: 10px 20px;
    margin: 0 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  .comment-menu-button {
    background: none;
    border: none;
    cursor: pointer;
   
  }
  
  .delete-comment-button {
    background: none;
    border: none;
    cursor: pointer;
    color: red;
  }
  .author-link{
    text-decoration : underline;
  }