/* PaymentPage.css */
.root-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  min-height: 100vh; /* 최소 높이를 100vh로 설정 */
}

.payment-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  width: 100%;
  max-width: 500px;
}

/* 이전 스타일 코드는 생략 */

.refund-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 1rem;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  max-width: 400px;
  width: 100%;
}

/* 추가적인 스타일 코드 */
.pay-money-display {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.input-field {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  width: 100%;
  max-width: 300px;
  margin-bottom: 0.5rem;
}

.styled-button {
  padding: 0.5rem 1rem;
  background-color: #04B486;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  margin-bottom: 0.5rem;
}

.styled-button:hover {
  background-color: #ff9800;
}



.dialog-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.dialog-content {
  display: flex;
  flex-direction: column;
}

.dialog-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.dialog-actions button {
  margin-left: 0.5rem;
}