.top-posts-section {
    margin-bottom: 2rem;
    display: flex;
  }
  
  .top-posts-container {
    display: flex;
    flex: 1;
  }
  
  .sns-sidebar {
    width: 200px;
    padding: 1rem;
    background-color: #f2f2f2;
    border-radius: 0.5rem;
  }
  

  .top-posts-content {
    flex: 1;
    padding: 1rem;
  }
  
  .top-section-title {
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1rem;
    color: red;
  }
  
  .no-posts-message {
    font-style: italic;
    color: #888;
  }
  
  .top-posts-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
  }
  
  .top-post-item {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .top-post-item:hover {
    transform: translateY(-5px);
  }
  
  .top-post-rank {
    font-size: 2rem;
    font-weight: bold;
    color: #ff6347;
    margin-right: 1.5rem;
  }
  
  .top-post-content {
    flex: 1;
  }
  
  .top-post-title {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
    text-decoration : underline;
    margin-bottom: 0.5rem;
    cursor: pointer;
  }
  
  .top-post-author {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 0.5rem;
    text-decoration : underline;
  }
  
  .top-post-author a {
    color: #666;
    text-decoration: none;
  }
  
  .top-post-meta {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    color: #888;
  }
  
  .top-post-likes,
  .top-post-comments {
    display: flex;
    align-items: center;
    margin-right: 1rem;
  }
  
  .top-post-likes i,
  .top-post-comments i {
    margin-right: 0.5rem;
    color: #ff6347;
  }

.top-hashtags-list{
    display: flexbox;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
}