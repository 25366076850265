/* ImageGalleryComponent.css */
.image-gallery-description {
    text-align: center;
    background-color: rgba(0, 0, 0, 0.7);
    color: black;
    
  }
/* 이미지 갤러리 전체 컨테이너 스타일 */
.image-gallery-container {
    display: flex;
    justify-content: center;
    align-items: center;;
    color: white;
    border:1px solid #2c3e50;
    height: 100%;
  }
  
  /* 이미지 갤러리 커스텀 스타일 */
  .image-gallery-custom {

    object-fit: cover; /* 이미지가 컨테이너를 채우도록 설정 */
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    
    margin: auto; /* 가운데 정렬 */
  }
  
  /* 이미지 갤러리 내부 이미지 스타일 */
  .image-gallery-image {

    object-fit: cover; /* 이미지 크기 고정 */
  }
  
  /* 이미지 갤러리 내부 이미지 캡션 스타일 */
 
  
  /* 이미지 갤러리 내부 화살표 버튼 스타일 */
.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
      width: 40px;
      height: 50px;

  }
  