.coupon-modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: absolute; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Correctly center the modal */
    max-height: 80%; 
    overflow-y: auto; /* Optional: only if you want a scrollbar */
    width: 50vw;
    
}
.coupon-modal {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex; 
    justify-content: center;
    align-items: center;
}

.modal-close {
    font-size: 30px;
    position: absolute;
    right: 15px;
    top: 10px;
    cursor: pointer;
}

.coupon-modal-card {
    border: 1px solid #ccc;
    display: flex;
    margin-bottom: 10px;
    padding: 10px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background: #f9f9f9; 
}
@media (max-width: 768px) {
    .coupon-modal-content {
        width: 90vw; /* On smaller screens, use 90% of the viewport width */
        padding: 10px; /* Reduce padding on smaller screens */
    }
}
.coupon-modal-info {
    color: #212529;
    text-align: center;
    margin-bottom: 12px;
  }