.sns-profile {
  display: flex;
  max-width: 1235px;
  margin: auto;
  border: 2px solid #dbdbdb; /* 굵은 구분선 */
  border-radius: 4px;
}

.sidebar {
  flex: 1;
  border-right: 2px solid #dbdbdb; /* 구분선 */
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* 버튼들 왼쪽 정렬 */
}

.sidebar-link {
  width: 100%;
  margin-bottom: 16px; /* 버튼 사이 간격 */
}

.sidebar-button {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 2px solid #dbdbdb;
  background-color: white;
  margin-bottom: 5px;
  cursor: pointer;
  font-weight: bold;
}

.main-content {
  flex: 3;
  padding: 20px;
}

.user-info {
  border-bottom: 2px solid #dbdbdb; /* 유저 정보와 게시글 사이 구분선 */
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.user-posts {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 열 단위 3개 게시글 */
  grid-gap: 28px;
  max-height: 500px; /* 고정 높이 설정 */
  overflow-y: auto; /* 내부 스크롤 활성화 */
  scrollbar-width: thin; /* 스크롤바 두께 설정 for Firefox */
  scrollbar-color: #d0d0d0 transparent; /* 스크롤바 색상 설정 for Firefox */
}

/* 스크롤바 스타일 for Chrome, Edge, and Safari */
.user-posts::-webkit-scrollbar {
  width: 8px; /* 스크롤바 너비 */
}

.user-posts::-webkit-scrollbar-thumb {
  background-color: #d0d0d0; /* 스크롤바 색상 */
  border-radius: 4px;
}

.user-posts::-webkit-scrollbar-track {
  background: transparent; /* 스크롤바 트랙 색상 */
}
.post-thumbnail-container {
  position: relative;
  cursor: pointer;
}

.post-thumbnail-container::before {
  content: '';
  display: block;
  padding-top: 100%; /* Makes a square */
}

.post-thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px; /* 이미지 모서리 둥글게 */
}

/* 호버 효과 추가 */
.post-thumbnail-container:hover .post-thumbnail {
  opacity: 0.9;
}

/* 추가할 수 있는 현대적인 디자인 요소들 */
button:hover,
.sidebar-button:hover {
  background-color: #f0f0f0; /* 호버 배경색 */
}

/* 반응형 디자인: 화면 크기에 따라 사이드바 숨기기 */
@media (max-width: 768px) {
  .sns-profile {
    flex-direction: column;
  }
  .sidebar {
    flex-direction: row;
    justify-content: center;
    border-right: none;
    border-bottom: 1px solid #dbdbdb;
  }
  .main-content {
    flex: 1;
  }
}
.profile-header {
  display: flex;
  align-items: center;
  padding: 20px;

  color: #fff; /* 글자색, 이미지에 맞게 변경하세요 */
}

.profile-img {
  width: 77px; /* 이미지 크기, 필요에 따라 조정하세요 */
  height: 77px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #fff; /* 프로필 이미지 테두리 */
  margin-right: 20px;
}

.username {
  font-size: 1.2rem; /* 사용자 이름의 글자 크기 */
  margin: 0;
  font-weight: bold;
}
