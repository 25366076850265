/* General styles */
body {

  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
}

/* Table styles */
.table {
  width: 80%;
  margin: 20px auto;
  background-color: #fff;
  border-collapse: collapse;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.table th, .table td {
  padding: 15px;
  text-align: left;
}

.table th {
  background-color: #4CAF50;
  color: white;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f2f2f2;
}

/* Buttons */
.btn {
  display: inline-block;
  padding: 10px 20px;
  margin: 10px;
  font-size: 16px;
  color: #fff;
  background-color: #4CAF50;
  border: none;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-outline-secondary {
  background-color: #007BFF;
}

.btn-outline-info {
  background-color: #17A2B8;
}

.btn-outline-danger {
  background-color: #DC3545;
}

.btn:hover {
  background-color: #3e8e41;
}

/* Modal styles */
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 80%;
  text-align: center;
}

/* Image popup */
.image-popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.image-popup-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.image-popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.image-popup-img {
  max-width: 100%;
  max-height: 80vh;
}

.my-3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.d-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.ml-2 {
  margin-left: 0.5rem;
}

.text-center {
  text-align: center;
}

/* Larger content section */
.content-section {
  max-height: 600px; /* Increased from 400px */
  overflow-y: auto;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 5px;
  border: 1px solid #ddd;
}
