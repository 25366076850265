/* Chat room container */
.chat-room-container {
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}
/* Add this class in your CSS file */
.button-spacing {
    background-color: blue;
    margin-left: 10px; /* Adjust the value as needed */
}
.clickable {
    text-decoration: underline;
    cursor: pointer;
    color: blue;
  }

/* Chat messages container */
.chat-messages {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    display: flex;
    flex-direction: column; /* 최근에 보낸 메시지를 위로 표시하도록 변경 */
}

/* Individual message style */
.message-bubble {
    max-width: 80%;
    padding: 8px 12px;
    border-radius: 20px;
    margin-bottom: 10px;
    position: relative; /* 포지션을 상대적으로 설정하여 보낸 시간을 올바르게 배치 */
}

.message-bubble .message-time {
    position: absolute;
    top: 50%; /* 상단을 기준으로 50% 위치 */
    font-size: 10px; /* 텍스트 크기 조정 */
    color: rgba(0, 0, 0, 0.5); /* 투명 회색으로 설정 */
}

.message-bubble.sent .message-time {
    left: -50px; /* 내가 보낸 채팅일 경우 시간을 왼쪽에 위치 */
}

.message-bubble.received .message-time {
    right: -50px; /* 다른 사용자가 보낸 채팅일 경우 시간을 오른쪽에 위치 */
}

.message-bubble.sent {
    background-color: #080e12;
    align-self: flex-end;
    color: #fff;
}

.message-bubble.received {
    background-color: #fff;
    align-self: flex-start;
    color: #000;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

/* 날짜 스타일 */
.message-date {
    text-align: center;
    margin-bottom: 10px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
}


/* Chat input container */
.chat-input {
    padding: 10px;
    background-color: #fff;
    border-top: 1px solid #e0e0e0;
    display: flex;
    align-items: center;
}

/* Input field style */
.chat-input input[type="text"] {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 20px;
    background-color: #f5f5f5;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

/* Button style */
.chat-input button {
    padding: 10px 20px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 20px;
    margin-left: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Button hover effect */
.chat-input button:hover {
    background-color: #45a049;
}
.chat-message-container {
    display: flex;
    flex-direction: column;
}

/* 닉네임 스타일 */
.message-nickname {
    font-size: 12px;
    margin-bottom: 5px;
    color: rgba(0, 0, 0, 0.6);
    text-align: center; /* 기본적으로 가운데 정렬 */
}

.message-nickname.sent {
    align-self: flex-end; /* 내가 보낸 메시지의 닉네임 오른쪽 정렬 */
}

.message-nickname.received {
    margin-bottom: 5px;
    text-align: left;
    font-size: 14px;
    color: #666;
}
.unread-count {
    color: #000;
    border-radius: 10px;
    padding: 0 5px;
    margin-left: 8px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 20px; 
    height: 20px;
}
.message-time {
    position: relative;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
    padding-top: 2px; /* 시간과 메시지 내용 사이의 간격 조정 */
}
.unread-indicator {
    position: absolute;
    right: -1px; 
    top: -10px; 
    color: #ff3b30; /* iOS 메시지 앱에서 사용하는 읽지 않은 메시지 색상과 유사 */
    font-size: 10px; /* 글자 크기를 작게 조정 */
    font-weight: bold; /* 글자 두껍게 */
    min-width: auto; /* 너비 제한 없음 */
    height: auto; /* 높이 제한 없음 */
}
.chat-complete-trade {
    text-align: center;
    padding: 20px;
  }
  
  .chat-complete-trade button {
    padding: 10px 20px;
    background-color: #04b486; /* 버튼 배경색 */
    color: white; /* 버튼 텍스트 색상 */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px; /* 버튼 텍스트 크기 */
    margin-top: 10px; /* 버튼 상단 여백 */
    transition: background-color 0.3s ease; /* 호버 효과를 위한 전환 */
  }
  
  .chat-complete-trade button:hover {
    background-color: #039f77; /* 호버 시 버튼 배경색 변경 */
  }
  .payment-modal {
    display: flex;
    position: fixed;
    z-index: 10001;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    align-items: center;
    justify-content: center;
  }
  
  .payment-modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    animation-name: animatetop;
    animation-duration: 0.4s;
    position: relative; /* 쿠폰 모달의 위치 기준 */
  }
  

.payment-modal-content p {
    color: black;
    font-size: 16px;
}

.payment-modal-content input {
    width: 95%;
    padding: 10px;
    margin: 10px 0;
}

.payment-modal-content button {
    background-color: #4CAF50; /* Green */
    color: white;
    padding: 14px 20px;
    margin: 10px 0;
    border: none;
    cursor: pointer;
    width: 100%;
}

.payment-modal-content button:hover {
    opacity: 0.8;
}

.close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

@keyframes animatetop {
    from {top: -300px; opacity: 0} 
    to {top: 0; opacity: 1}
}
/* ... 기존 CSS ... */
.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* 반투명 배경 */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1001;
  }
  
  .modal {
    color: #000;
    background: green;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1002; /* 가장 높은 z-index 값 */
  }
  .modal button {
    position: absolute;
    top: 10px;
    right: 10px;
  }

