/* General styles */
.custom-navbar {
  background-color: #04B486;
  color: #ffffff;
  padding: 10px 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.custom-navbar .navbar-brand {
  font-size: 1.5rem;
  font-weight: bold;
}

.custom-navbar .navbar-nav .nav-item .nav-link {
  color: #ffffff;
  margin-right: 20px;
  transition: color 0.3s ease;
}

.custom-navbar .navbar-nav .nav-item .nav-link:hover {
  color: #ffeb3b; /* Bright yellow on hover for better visibility */
}

.custom-navbar .navbar-nav .nav-item .nav-link i {
  margin-right: 5px;
  color: beige;
}

/* Toggler styles */
.custom-toggler {
  border-color: transparent;
}

.custom-toggler .navbar-toggler-icon {
  color: #ffffff;
}

@media (max-width: 768px) {
  .custom-navbar .navbar-nav {
    flex-direction: column;
    align-items: flex-start;
  }

  .custom-navbar .navbar-nav .nav-item .nav-link {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.custom-navbar .navbar-nav.flex-row {
  display: flex;
  flex-direction: row;
}

.custom-navbar .fa-bell {
  color: #ffffff;
}

.custom-navbar .nav-item span.nav-link {
  font-size: 1rem;
  font-weight: bold;
}

.custom-navbar .nav-item span.nav-link i {
  margin-left: 5px;
}
