/* General modal overlay styles */
.search-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; /* Full width to cover the whole screen when active */
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10001;
  display: flex;
}

/* Modal styles */
.search-modal {
  width: 30%; /* Fixed width for the sidebar */
  height: 100%; /* Full height */
  background: #fff;
  box-shadow: 4px 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column; /* Stack items vertically */
  padding: 20px;
  box-sizing: border-box; /* Include padding in width */
}

/* Content styles */
.search-modal-content {
  flex: 1; /* Take up remaining space */
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Input styles */
.search-modal-content input[type="text"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  font-size: 16px;
}

/* Button styles */
.search-modal-content button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-modal-content button:hover {
  background-color: #45a049;
}

.search-modal-close {
  align-self: flex-end;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #888;
}

/* Search condition selector styles */
.search-condition-selector {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.search-condition-selector label {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.search-condition-selector input[type="radio"] {
  cursor: pointer;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .search-modal {
      width: 80%; /* Take up most of the screen width on small devices */
  }

  .search-modal-content {
      gap: 15px;
  }

  .search-modal-content button {
      font-size: 14px;
      padding: 8px 16px;
  }

  .search-condition-selector {
      gap: 5px;
  }

  .search-condition-selector label {
      font-size: 14px;
  }
}
