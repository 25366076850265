
.coupon-list-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 80vh; /* 적절한 높이 설정 */
    overflow-y: auto;
  }
  
  .coupon-container {
    padding: 20px;
    background-color: #effff4;
    border: 1px solid #c4e3d5;
    border-radius: 10px;
    margin: auto;
    width: 90%; /* 원하는 너비로 설정 */
  }
  
.coupon-card {
  background-color: #f8f9fa;
  margin: 10px 0;
  padding: 20px;
  text-align: center;
  border-radius: 16px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  
  max-width: 400px; /* 적절한 최대 너비 설정 */
  transition: transform 0.3s ease-in-out;
}

.coupon-card:hover {
  transform: scale(1.03);
}

.coupon-info {
  color: #212529;
  text-align: center;
  margin-bottom: 12px;
}

.coupon-info h3 {
  font-size: 1.4em;
  font-weight: 600;
  margin-bottom: 5px;
}

.coupon-info p {
  font-size: 1em;
}

.coupon-validity {
  background-color: #d1e7dd;
  color: #0f5132;
  border-radius: 8px;
  padding: 10px;
  font-size: 0.9em;
  text-align: center;
}

  .coupon-validity p {
    background-color: #c4e3d5;
    color: #1b4332;
  }
  
  .no-coupons {
    text-align: center;
    color: #1b4332;
  }
  