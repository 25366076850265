.sns-post-list {
  width: 100%;
  height: 100%;
  max-width: 100%;
  margin: 0;
  padding: 20px;
  background: #f4f5f7;
  display: grid;
  grid-template-columns: 1fr 4fr;
  box-sizing: border-box;
}

.write-post-link {
  grid-column: 1 / 2;
  align-self: start;
  justify-self: start;
}

.sns-sidebar {
  min-width: 25%;
  border-right: 2px solid #dbdbdb;
  padding: 20px;
  box-sizing: border-box;
}

.sidebar-link {
  display: block;
  margin-bottom: 10px;
}

.sidebar-button {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 2px solid #dbdbdb;
  background-color: white;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.sidebar-button:hover {
  background-color: #04B486;
  color: white;
}

.posts-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px;
}

.post-card {
  background: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  border: 1px solid #dbdbdb;
}

.post-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.post-header {
  display: flex;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #dbdbdb;
}

.sns-profile-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.author-link {
  color: #2c3e50;
  font-weight: bold;
  margin-left: 10px;
  text-decoration: none;
}

.author-link:hover {
  text-decoration: underline;
}

.post-time {
  color: #888;
  font-size: 0.85em;
  margin-left: auto;
}

.post-title {
  padding: 15px;
  font-size: 1.25rem;
  font-weight: bold;
  color: #2c3e50;
}

.post-image-container {
  position: relative;
  overflow: hidden;
}

.post-image {
  width: 100%;
  object-fit: cover;
}

.post-content {
  padding: 15px;
}

.post-text {
  color: #666;
  font-size: 14px;
  margin-bottom: 10px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 4.5em;
  white-space: pre-wrap;
  word-break: break-all;
}

.expanded {
  max-height: none;
}

.show-more {
  color: #0095f6;
  font-size: 14px;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
}

.likes-and-comments {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.likes, .comments {
  display: flex;
  align-items: center;
  margin-right: 15px;
  font-size: 14px;
  color: #888;
}

.likes i, .comments i {
  margin-right: 5px;
  cursor: pointer;
}

.likes .fa-heart {
  color: #ccc;
}

.likes .fa-heart.liked {
  color: red;
}

.hashtags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.hashtag {
  background-color: #0095f6;
  color: white;
  border-radius: 5px;
  padding: 3px 6px;
  font-size: 12px;
}

.comment-input-container {
  display: flex;
  border-top: 1px solid #dbdbdb;
  padding: 8px 15px;
  align-items: center;
}

.comment-input {
  flex: 1;
  padding: 8px;
  margin-right: 8px;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
}

.post-comment-button {
  background-color: #0095f6;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  cursor: pointer;
}

.view-count {
  color: #949393;
}

.popular-posts-header {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
  flex-wrap: wrap;
}

.popular-button {
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 20px;
  background-color: #04B486;
  color: white;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-weight: bold;
}

.popular-button:hover {
  background-color: #039e74;
}

.posts-divider {
  border-bottom: 2px solid #111010;
  height: 2px;
  width: 100%;
  margin: 20px 0;
}

@media (max-width: 768px) {
  .sns-post-list {
    grid-template-columns: 1fr;
  }

  .posts-container {
    margin-left: 0;
    padding: 10px;
  }

  .sns-sidebar {
    min-width: 100%;
    border-right: none;
    border-bottom: 2px solid #dbdbdb;
  }

  .post-card {
    border-radius: 5px;
  }

  .post-content, .post-text, .show-more, .likes, .comments, .hashtags {
    font-size: 12px;
  }

  .post-image {
    height: auto;
  }

  .comment-input-container {
    flex-direction: column;
    padding: 8px;
  }

  .comment-input {
    margin-right: 0;
    margin-bottom: 8px;
  }

  .post-comment-button {
    width: 100%;
  }

  .fa-heart {
    font-size: 20px;
  }
}
